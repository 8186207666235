import { useMutation } from '@apollo/client';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SCOPES } from 'components/authorization/authorize';
import Condition from 'components/forms/condition';
import FormWatcher from 'components/forms/formWatcher';
import Loading from 'components/loading/loading';
import NumberField from 'components/numberField/numberField';
import GozioSelect from 'components/selector/selector';
import TextField from 'components/textField/textField';
import { FlamingoContext } from 'contexts/flamingo';
import { CREATE_NETWORK_NOTIFICATION } from 'graphql/queries';
import { getFormLabel } from 'helpers/form-util';
import { buildLanguageSchema, LANGUAGE_CODES } from 'helpers/lang-util';
import useActiveNetworkLanguages from 'hooks/useActiveNetworkLanguages';
import useHandleError from 'hooks/useHandleError';
import useToast from 'hooks/useToast';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { makeValidate, Radios, TextField as FinalTextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';
import useCheckGozioAdmin from 'hooks/useCheckGozioAdmin';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import InfoPanel from 'components/infoPanel/infoPanel';
import GenericModal from 'components/genericModal/genericModal';
import { useFindNetworkVisibilityRule } from 'hooks/dataHooks/useNetworkVisibilityRule';
import { capitalize } from 'helpers/lang-util';

const PUSH_NOTIFICATION_ERROR_FORM_FIELD_MAP = new Map([
  ['radius', { label: 'Radius', section: 'Geolocation' }],
  ['lat', { label: 'Latitude', section: 'Geolocation' }],
  ['long', { label: 'Longitude', section: 'Geolocation' }],
  ['titleEN', { label: 'Title', section: 'Notification Content' }],
  ['messageEN', { label: 'Message', section: 'Notification Content' }],
  ['titleES', { label: 'Title', section: 'Notification Content (Spanish)' }],
  ['messageES', { label: 'Message', section: 'Notification Content (Spanish)' }],
  ['visibilityRuleCustom', { label: 'JSON Input', section: 'Notification Settings' }],
]);

const buildStyles = ({ theme }) => ({
  root: {
    paddingBottom: '70px',
  },
  footer: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: '16px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: theme.palette.grey[50],
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    zIndex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formRoot: {},
  discardBtn: {
    marginLeft: '24px',
  },
  saveBtn: {
    marginRight: '8px',
  },
  sectionHeading: {
    marginTop: '24px',
  },
  sectionField: {
    marginTop: '16px',
  },
});

const Send = ({ networkId, data }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });
  const isUberAdmin = useCheckGozioAdmin();

  const { toastNotificationSuccessHook } = useToast();
  const { handleError } = useHandleError('PushNotification');

  const [appID, setAppID] = useState('');
  const [esEnabled, setesEnabled] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { data: visibilityRules } = useFindNetworkVisibilityRule();

  const { authorize, activeNetwork } = useContext(FlamingoContext);

  const features = useMemo(() => activeNetwork?.configuration?.features, [activeNetwork]);

  const languages = useActiveNetworkLanguages();

  const initialValues = {
    langES: esEnabled,
    titleEN: null,
    titleES: null,
    messageEN: null,
    messageES: null,
    audience: 'all',
    appID: appID,
    visibilityRuleSelect: 'allUsers',
    geoType: 'inside',
    radius: 0,
  };

  const appIdField = useMemo(() => (
    <Field
      name="appID"
      component={({ input }) => (
        <GozioSelect
          input={input}
          label="App ID/Version"
          options={
            data?.getNetwork?.mobileApps
              ? data.getNetwork.mobileApps.map((appID) => ({
                id: appID.id,
                label: appID.name,
              }))
              : [{ id: '', label: 'None' }]
          }
        />
      )}
    />
  ), [data]);

  const visibilityRuleField = useMemo(() => {
    const visibilityRuleOptions = isUberAdmin ? [
      {
        id: 'allUsers',
        label: 'All Users',
      },
      {
        id: 'custom',
        label: 'Custom',
      },
    ] : [
      {
        id: 'allUsers',
        label: 'All Users',
      },
    ];
    return (
      <Field
        name="visibilityRuleSelect"
        component={({ input }) => (
          <GozioSelect
            input={input}
            label="Visibility Rule"
            disabled={features.pushNotificationVisibilityRules !== 'on'}
            options={
              visibilityRules
                ? visibilityRuleOptions.concat(visibilityRules.map((rule) => ({
                  id: rule.id,
                  label: capitalize(rule.name),
                })))
                : visibilityRuleOptions
            }
          />
        )}
      />
    );
  }, [
    features.pushNotificationVisibilityRules,
    isUberAdmin,
    visibilityRules,
  ]);

  const validate = languages
    ? makeValidate(
      Yup.object().shape(
        buildLanguageSchema(
          {
            titleEN: Yup.string()
              .nullable()
              .max(40, 'Title must be 40 characters or less')
              .required('Title is required'),
            messageEN: Yup.string()
              .nullable()
              .max(120, 'Message must be 120 characters or less')
              .required('Message is required'),
            linkEN: Yup.string().matches(/^\w+:(\/?\/?)[^\s]+/i, {
              message:
                'URL must include http or https, e.g. https://www.goziohealth.com',
              excludeEmptyString: true,
            }),
            titleES: Yup.string()
              .nullable()
              .when('langES', {
                is: true,
                then: (schema) => schema
                    .max(40, 'Title must be 40 characters or less')
                    .required('Title is required'),
              }),
            messageES: Yup.string()
              .nullable()
              .when('langES', {
                is: true,
                then: (schema) => schema
                    .max(120, 'Message must be 120 characters or less')
                    .required('Message is required'),
              }),
            linkES: Yup.string().matches(/^\w+:(\/?\/?)[^\s]+/i, {
              message:
                'URL must include http or https, e.g. https://www.goziohealth.com',
              excludeEmptyString: true,
            }),
            radius: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema.required().positive('Radius must be a postive number.'),
            }),
            lat: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema
                  .min(-90, 'Latitude values must be between -90 to 90')
                  .max(90, 'Latitude values must be between -90 to 90')
                  .required('Latitude is required'),
            }),
            long: Yup.number().when('audience', {
              is: 'geo',
              then: (schema) => schema
                  .min(-180, 'Longitude values must be between -180 to 180')
                  .max(180, 'Longitude values must be between -180 to 180')
                  .required('Longitude is required'),
            }),

            visibilityRuleCustom: Yup.string().when('visibilityRuleSelect', {
              is: 'custom',
              then: (schema) => schema
                .test('isJSON', 'Invalid JSON input', (value) => {
                  if (!value) return true;
                  try {
                    JSON.parse(value);
                    return true;
                  } catch (e) {
                    return false;
                  }
                })
                .required('Visibility Rule is required'),
            }),
          },
          languages,
          {
            titleEN: LANGUAGE_CODES.ENGLISH,
            messageEN: LANGUAGE_CODES.ENGLISH,
            linkEN: LANGUAGE_CODES.ENGLISH,
            titleES: LANGUAGE_CODES.SPANISH,
            messageES: LANGUAGE_CODES.SPANISH,
            linkES: LANGUAGE_CODES.SPANISH,
          },
        ),
      ),
    )
    : null;

  const [sendNotification] = useMutation(CREATE_NETWORK_NOTIFICATION, {
    errorPolicy: 'all',
    context: { headers: { network: networkId } },
    onCompleted: (data) => {
      toastNotificationSuccessHook('Notification Sent');
    },
    onError: ({ graphQLErrors, networkError }) => handleError(graphQLErrors, networkError, 'Message failed to send'),
  });

  useEffect(() => {
    if (languages) {
      setesEnabled(includes(languages, LANGUAGE_CODES.SPANISH));
    }
  }, [languages]);

  useEffect(() => {
    if (data?.getNetwork?.mobileApps) {
      const customerReleaseApp = find(data.getNetwork.mobileApps, [
        'type',
        'customerRelease',
      ]);
      setAppID(customerReleaseApp ? customerReleaseApp.id : '');
    }
  }, [data]);

  const onSubmit = async (values) => {
    const newMessage = {
      mobileApp: values.appID || appID,
      title: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.titleEN }],
      body: [{ lang: LANGUAGE_CODES.ENGLISH, label: values.messageEN }],
      actionUrl: [],
    };

    if (values.visibilityRuleSelect === 'custom') {
      newMessage.visibilityRuleCustom = values.visibilityRuleCustom;
    } else if (values.visibilityRuleSelect === 'allUsers') {
      newMessage.visibilityRuleCustom = null;
      newMessage.visibilityRule = null;
    } else {
      newMessage.visibilityRule = values.visibilityRuleSelect;
    }

    if (values.linkEN) newMessage.actionUrl.push({
        lang: LANGUAGE_CODES.ENGLISH,
        label: values.linkEN,
      });
    if (esEnabled) {
      newMessage.title.push({
        lang: LANGUAGE_CODES.SPANISH,
        label: values.titleES,
      });
      newMessage.body.push({
        lang: LANGUAGE_CODES.SPANISH,
        label: values.messageES,
      });
      if (values.linkES) newMessage.actionUrl.push({
          lang: LANGUAGE_CODES.SPANISH,
          label: values.linkES,
        });
    }
    if (values.audience === 'geo') {
      newMessage.geofence = {
        type: values.geoType,
        latitude: parseFloat(values.lat),
        longitude: parseFloat(values.long),
        radius: Math.floor(parseFloat(values.radius) * 1609),
      };
    }
    await sendNotification({ variables: { input: newMessage } });
  };

  const onFormReset = (form) => {
    form.resetFieldState('titleEN');
    form.resetFieldState('messageEN');
    form.resetFieldState('linkEN');
    if (esEnabled) {
      form.resetFieldState('titleES');
      form.resetFieldState('messageES');
      form.resetFieldState('linkES');
    }
    form.reset();
  };

  if (languages?.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <Grid container direction="column" spacing={2} sx={styles.root}>
        <Grid item sx={{ paddingBottom: 0 }}>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, invalid, values }) => (
              <form>
                {!pristine && (
                  <FormWatcher
                    allowContinueOnError
                    formRenderProps={{ form }}
                    errorFormFieldMap={PUSH_NOTIFICATION_ERROR_FORM_FIELD_MAP}
                    isClosing={isClosing}
                    isSubmitting={isSubmitting}
                    onClose={() => {
                      setIsClosing(false);
                      setIsSubmitting(false);
                    }}
                    unsavedConfirmText="Send Message"
                    continueAndDeleteText="Continue & Delete Work"
                  />
                )}
                    <Grid item>
                      <Typography variant="h3">Notification Settings</Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: '12px', marginTop: '24px' }}>
                    {authorize(SCOPES.PUSH_NOTIFICATIONS.HAS_APP_ID) && (
                      <Grid item xs={6}>
                        {appIdField}
                      </Grid>
                    )}
                    </Grid>
                    {(<Grid item>
                      <Grid container sx={{ alignItems: 'baseline' }}>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginBottom: '12px', marginTop: '24px', color: features.pushNotificationVisibilityRules === 'on' ? 'text.primary' : '#ccc' }}
                          >
                            Select Visibility Rule
                          </Typography>
                        </Grid>
                        {features.pushNotificationVisibilityRules !== 'on' && !isUberAdmin && (<Grid item>
                          <InfoTooltip
                            sx={{ marginLeft: 1 }}
                            title={
                              'This functionality is currently unavailable. Please contact Gozio support to enable this feature.'
                            }
                          />
                        </Grid>)}
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          {visibilityRuleField}
                        </Grid>
                      </Grid>
                      {features.pushNotificationVisibilityRules === 'on' && (
                        <Grid container style={{ marginTop: 32 }}>
                          <Condition when="visibilityRuleSelect" is="custom">
                            <Grid item xs={6}>
                              <FinalTextField
                                label={getFormLabel('JSON Input')}
                                multiline
                                name="visibilityRuleCustom"
                                variant="outlined"
                                minRows={7}
                                maxRows={7}
                              />
                            </Grid>
                          </Condition>
                        </Grid>
                      )}
                    </Grid>)}
                    <Grid item xs={12}>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: '12px', marginTop: '24px' }}
                          >
                          Geolocation
                          </Typography>
                          <Grid container>
                            <Typography variant="body1">Send based on Geographic Area?</Typography>
                            <InfoTooltip
                              sx={{ marginLeft: 1 }}
                              title={
                                'Geolocation notifications are sent based on the last known location when app was first opened.'
                              }
                            />
                          </Grid>
                          <Radios
                            radioGroupProps={{ style: {
                              display: 'flex',
                              flexDirection: 'row',
                            } }}
                            name="audience"
                            color="primary"
                            data={[
                              { label: 'No', value: 'all' },
                              { label: 'Yes', value: 'geo' },
                            ]}
                          />
                        </Grid>
                      <Condition when="audience" is="geo">
                        <Grid
                          container
                          direction="column"
                          spacing={0}
                        >
                          <Grid
                            container
                            sx={{ marginTop: 1 }}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <Field
                                name="geoType"
                                component={({ input }) => (
                                  <GozioSelect
                                    input={input}
                                    label="Geo Type"
                                    options={[
                                      { id: 'inside', label: 'Include' },
                                      { id: 'outside', label: 'Exclude' },
                                    ]}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                all users within
                              </Typography>
                            </Grid>
                            <Grid item>
                              <NumberField
                                label={getFormLabel('Radius', true)}
                                name="radius"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">miles of</Typography>
                            </Grid>
                            <Grid item>
                              <NumberField
                                label={getFormLabel('Latitude', true)}
                                name="lat"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item>
                              <NumberField
                                label={getFormLabel('Longitude', true)}
                                name="long"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <Grid item sx={{ marginTop: 2 }}>
                            <Link
                              href="https://www.gps-coordinates.net/"
                              target="_blank"
                            >
                              Find your latitude and longitude coordinates
                            </Link>
                          </Grid>
                        </Grid>
                      </Condition>
                      <Grid item sx={{ marginTop: 3 }}>
                        <Typography variant="h3">Notification Content</Typography>
                      </Grid>
                      <Grid item>
                        <InfoPanel
                          title={'Push Notification content must be generic in nature and not include any personal information.'}
                          sx={{ marginTop: '8px', width: 'max-content' }}
                        />
                      </Grid>

                      <Grid container>
                        <Grid item xs={5.5} >
                          <Grid item xs={12} sx={styles.sectionHeading}>
                            <Typography variant="subtitle1">Content (English)</Typography>
                          </Grid>
                          <Grid item sx={styles.sectionField}>
                            <Field name="titleEN">
                              {({ input, meta }) => (
                                <TextField
                                  label={getFormLabel('Title', true)}
                                  charLimit={40}
                                  helperText={meta.touched ? meta.error : ''}
                                  error={meta.touched && !!meta.error}
                                  {...input}
                                  fullWidth
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sx={styles.sectionField}>
                            <Field name="messageEN">
                              {({ input, meta }) => (
                                <TextField
                                  label={getFormLabel('Message', true)}
                                  charLimit={120}
                                  helperText={meta.touched ? meta.error : ''}
                                  error={meta.touched && !!meta.error}
                                  {...input}
                                  fullWidth
                                  multiline
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sx={styles.sectionField}>
                            <FinalTextField
                              label="Web link (optional)"
                              name="linkEN"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Condition when="langES" is={true}>
                          <Grid item xs={5.5} style={{ marginLeft: 24 }}>
                            <Grid item sx={styles.sectionHeading}>
                              <Typography variant="subtitle1">
                                Content (Spanish)
                              </Typography>
                            </Grid>
                            <Grid item sx={styles.sectionField}>
                              <Field name="titleES">
                                {({ input, meta }) => (
                                  <TextField
                                    label={getFormLabel('Title', true, true)}
                                    charLimit={40}
                                    helperText={meta.touched ? meta.error : ''}
                                    error={meta.touched && !!meta.error}
                                    {...input}
                                    fullWidth
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item sx={styles.sectionField}>
                              <Field name="messageES">
                                {({ input, meta }) => (
                                  <TextField
                                    label={getFormLabel('Message', true, true)}
                                    charLimit={120}
                                    helperText={meta.touched ? meta.error : ''}
                                    error={meta.touched && !!meta.error}
                                    {...input}
                                    fullWidth
                                    multiline
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item sx={styles.sectionField}>
                              <FinalTextField
                                label="Web link (optional)"
                                name="linkES"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Condition>
                      </Grid>


                      <Box sx={styles.footer}>
                        <Button
                          sx={styles.discardBtn}
                          variant="outlined"
                          type="button"
                          onClick={() => {
                            onFormReset(form);
                          }}
                          color="primary"
                        >
                          Clear
                        </Button>
                        <Button
                          sx={styles.saveBtn}
                          variant="contained"
                          color="primary"
                          type="button"
                          disabled={submitting || pristine || invalid}
                          onClick={() => setShowModal(true)}
                        >
                          Send Notification
                        </Button>
                      </Box>

                    {showModal === true && (
                      <GenericModal
                        title={'Your Notification Is Ready'}
                        scrollable
                        bodyProgress={isSubmitting}
                        body={
                          <>
                            <Typography variant="body1">
                              Ready to send your notification? Once sent this action cannot be undone.
                            </Typography>
                          </>
                        }
                        open={showModal === true}
                        handleClose={() => {
                          setShowModal(false);
                        }}
                        handleConfirm={async (e) => {
                          setIsSubmitting(true);
                          await handleSubmit(form.getState()?.values);
                          onFormReset(form);
                          setShowModal(false);
                          setIsSubmitting(false);
                        }}
                        confirmText={'Send Notifiction'}
                        disableCancel={false}
                      />
                    )}

                    </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

Send.propTypes = {
  networkId: PropTypes.string,
  data: PropTypes.object,
};

Send.defaultProps = {
  networkId: '',
};

export default Send;
